.login__bg{
    background-image: url("https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80");
    height: 92vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}
.non{
    background-color: rgba(255, 255, 255, 0);
    position: relative;
}