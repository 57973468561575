.typewriter h1 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 4.5s steps(40, end),
      blink-caret .75s step-end infinite;
      color: rgb(0, 0, 0);
  }
  .typewriter p{
    color: rgb(87, 87, 87);
  }
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    10% { border-color: rgb(8, 184, 2); }
    30% { border-color: rgb(3, 167, 207); }
    50% { border-color: rgb(240, 217, 12); }
    70% { border-color: rgb(136, 4, 96); }
    90% { border-color: rgb(206, 6, 83); }
  }
  