.title{
    display: flex;
}
a.nav-link{
    color: #000000;
}
a.active.nav-link{
    color: rgb(0, 0, 0) !important;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-dark{
    /* position: sticky  !important;
    top: 0; */
    max-width: 100%;
    z-index: 10 !important;
    background-color: #fff !important;
}
span.navbar-toggler-icon{
    color: rgb(255, 0, 0) !important;
}