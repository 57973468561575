
  @media only screen and (min-width:600px) {
    
    .App {
      text-align: center;
    }
    
  }



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Animation{
  z-index: 3;
}

/* blur */
.blur {
  position: fixed;
  width: 200rem;
  height: 50rem;
  border-radius: 50%;
  background: #3ed3f8;
  filter: blur(150px);
  animation: blur 15s linear infinite;
  
}
.blur2 {
  position: fixed;
  top: 0px;
  width: 200rem;
  height: 50rem;
  border-radius: 50%;
  background: #e494b3;
  filter: blur(150px);
  animation: blur2 15s linear infinite;
  
}
.blur3 {
  position: fixed;
  width: 200rem;
  height: 50rem;
  border-radius: 50%;
  background: #2abd85;
  filter: blur(150px);
  animation: blur3 15s linear infinite;
  
}
@keyframes blur {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
  }
}

@keyframes blur2 {
  0%{
    transform: rotate(-0deg);
  }
  100%{
    transform: rotate(-360deg);
  }
}

@keyframes blur3 {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
